


















import { Component, Vue } from 'vue-property-decorator'
import get from 'lodash.get'

@Component
export default class DemoIframe extends Vue {
  get url() {
    let url = window.location.origin
    const episodeId = get(this.$route.params, 'episodeId', '')
    url = episodeId ? `${url}/v2/${episodeId}?` : ''
    return url
  }
}
